import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import css, { get } from '@styled-system/css'
import { createShouldForwardProp } from '@styled-system/should-forward-prop'
import space from '@styled-system/space'
import color from '@styled-system/color'
import Video from 'react-responsive-video'
import Image from './Image'

const shouldForwardProp = createShouldForwardProp([...space.propNames, ...color.propNames])

const sx = props => css(props.sx)(props.theme)
const base = props => css(props.__css)(props.theme)
const variant = ({ theme, variant, __themeKey = 'variants' }) =>
  css(get(theme, __themeKey + '.' + variant, get(theme, variant)))

const Box = styled('div', {
  shouldForwardProp,
})(base, variant, space, color, sx, props => props.css)

const propTypes = {
  minHeight: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  reverseHover: PropTypes.bool,
  paddingContent: PropTypes.string,
  bottomLink: PropTypes.bool,
  fluid: PropTypes.object,
  src: PropTypes.string,
  children: PropTypes.node,
}

const defaultProps = {
  //minHeight: '400px',
  alignItems: 'center',
  justifyContent: 'center',
  reverseHover: false,
  paddingContent: '5vw',
  bottomLink: false,
  fluid: null,
  src: '',
  children: null,
}

const TileBox = styled(Box)`
  display: flex;
  position: relative;
  width: 100%;
  min-height: ${props => props.minHeight};
  ${props => props.src && `background: no-repeat center/100% url(${props.src});`}
  ${props => props.src && 'background-size: cover;'}
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  z-index: 2;
  & > img {
    opacity: ${props => (props.reverseHover ? 1 : 0.6)};
    transition: all 0.2s ease-out;
    &:hover {
      opacity: ${props => (props.reverseHover ? 0.6 : 1)};
    }
  }
  & a span {
    position:absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 1;
    background-color: black;
    opacity: ${props => (props.reverseHover ? 0 : 0.3)};
    transition: all 0.2s ease-out;
    &:hover {
      opacity: ${props => (props.reverseHover ? 0.3 : 0)};
    }
  }
  @media (min-width: 800px) {
    //width: 50%;
    &.f25 { width: 25%; }
    &.f33 { width: 33%; }
    &.f50 { width: 50%; }
    &.f66 { width: 67%; }
    &.f75 { width: 75%; }
    &.f100 { width: 100%; }
  }
`
const BackgroundImage = styled(GatsbyImage)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  object-position: center;
  // TODO: next two attributes are just a shot in the dark
  object-fit: contain;
  ${props => props.backgroundSize && props.backgroundSize}
  z-index: 2;
`
const TileContent = styled.div`
  position: absolute;
  //max-width: 800px;
  padding: ${props => props.paddingContent};
  justify-content: start;
  justify-items: start;
  justify-self: start;
  align-items: flex-end;

  z-index: 3;
  ${props =>
    props.bottomLink &&
    `
    display: flex;
    flex-direction: row;
    
    align-items: center;
    justify-content: space-between;
    & > * {
      display: inline-block;
    }`}
`
const Tile = props => (
  <TileBox {...props}>
    {props.children ? (
      <>
        <TileContent paddingContent={props.paddingContent} bottomLink {...props}>
          {props.children}
        </TileContent>
        <Image {...props} sx={{ position: `absolute !important`, top: 0, left: 0 }} />
      </>
    ) : (
      <Image {...props} />
    )}
    <Video {...props} />
  </TileBox>
)

Tile.propTypes = propTypes
Tile.defaultProps = defaultProps

export default Tile
